import { ExtractPropTypes, PropType } from '@vue/runtime-core'

export enum CIRCLE_BUTTON_ARROWS {
    UP = 'up',
    RIGHT = 'right',
    DOWN = 'down',
    LEFT = 'left',
}

export const buttonData = {
    title: {
        type: String,
        default: ''
    },
    link: {
        type: String,
        default: ''
    },
    arrow: {
        type: String as PropType<CIRCLE_BUTTON_ARROWS>,
        default: CIRCLE_BUTTON_ARROWS.DOWN
    },
    background: {
        type: String,
        default: 'transparent'
    }
}

export type ButtonData = ExtractPropTypes<typeof buttonData>
